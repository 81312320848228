import React from 'react';
import {
  CodeSnippet,
  Link,
  List,
  PlatformTab,
  PropList,
  PropListItem,
  Section,
} from 'docComponents';
import { Text, Environment, Level } from 'hudl-uniform-ui-components';

const snippet = require('raw-loader!../../../../../data/snippets/environment.example');

const WebTab = () => {
  return (
    <PlatformTab>
      <CodeSnippet
        scope={{
          React: React,
          Environment: Environment,
          Level: Level,
          Text: Text,
        }}
        code={snippet}
        platform="react"
        gitHubLink="themes/environment"
      />

      <Section title="Props">
        <PropList>
          <PropListItem name="env" types={['oneOf', 'string']}>
            <Text>
              Determines the environment applied to themable children
              components.
            </Text>
            <List type="unordered">
              <li>
                <code>light</code> (default)
              </li>
              <li>
                <code>dark</code>
              </li>
            </List>
          </PropListItem>

          <PropListItem name="qaId" types={['string']}>
            <Text>
              A unique string to control this environment in automated tests.
            </Text>
          </PropListItem>

          <PropListItem name="className" types={['string']}>
            <Text>
              Any of the{' '}
              <Link href="/resources/code-helpers/space?web">
                uniCSS space classes
              </Link>
              .
            </Text>
          </PropListItem>
        </PropList>
      </Section>
    </PlatformTab>
  );
};

export default WebTab;
