import React from 'react';
import { graphql } from 'gatsby';
import { PageHero, PageWithSubNav, PlatformTabs } from 'docComponents';
import {
  AndroidTab,
  AppleTab,
  OtherTab,
  ReactNativeTab,
  WebTab,
} from './platforms/_index.js';
import pageHeroData from '../../../../data/pages/components.yml';

const IndexPage = ({ data }) => {
  return (
    <PageWithSubNav
      pageType="code"
      title="Environment"
      designCode
      subnav="components">
      <PageHero
        heroData={pageHeroData}
        tierTwo="Themes"
        tierThree="Environment"
      />

      <PlatformTabs>
        <WebTab type="web" />
        <ReactNativeTab data={data} type="reactnative" />
        <AndroidTab data={data} type="android" />
        <AppleTab data={data} type="apple" />
        <OtherTab data={data} type="other" />
      </PlatformTabs>
    </PageWithSubNav>
  );
};

export default IndexPage;

export const query = graphql`
  query environmentComponentCode {
    allColorsYaml(filter: { type: { eq: "Background" } }) {
      edges {
        node {
          type
          light_environment_colors {
            value
            android_var
            sass_var
            apple_var
            rn_var
          }
          dark_environment_colors {
            value
            sass_var
            apple_var
            android_var
            rn_var
          }
        }
      }
    }
  }
`;
